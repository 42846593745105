<template>
    <v-dialog
        v-model="dialog_howworks"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
        lazy
    >
        <!-- <v-layout row class="pa-3"> -->
        <v-layout align-center justify-center row fill-height>
            <v-container class="pa-0">
                <v-row justify="space-around">
                    <v-card>
                        <v-img
                            height="100vh"
                            src="../img/usinas/5.jpg"
                            width="100%"
                            aspect-ratio="2"
                            fill-height
                            gradient="to top right, rgba(100,115,201,.25), rgba(25,32,72,.98)"
                        >
                            <v-app-bar
                                flat
                                color="rgba(0, 0, 0, 0)"
                            >
                                <!-- <v-app-bar-nav-icon color="white"></v-app-bar-nav-icon> -->

                                <!-- <v-toolbar-title class="title white--text pl-0">
                                Messages
                                </v-toolbar-title> -->

                                <v-spacer></v-spacer>
                                <v-layout align-top justify-end row>
                                    <v-btn
                                        flat
                                        @click="dialog_howworks = false"
                                        >
                                            <v-icon color="white">mdi-close</v-icon>
                                    </v-btn>
                                </v-layout>
                                <!-- <v-btn
                                    color="white"
                                    icon
                                >
                                <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn> -->
                            </v-app-bar>
                            <!-- <v-card-title class="white--text mt-8">
                                <h2 class="font-weight-light display-1">Energia limpa e economia de forma prática, digital e acessivel!</h2>
                            </v-card-title> -->
                            <div class="d-flex align-end">
                                <v-card-text class="white--text mt-8 display-1 align-end" style="padding:150px 20px 20px 20px">
                                    <div class="display-1 align-end">Energia limpa e economia de forma prática, digital e acessivel!</div>
                                    <div pt-6 class="font-weight-light white--text headline pt-6" style="padding-top:20px">Um jeito diferente e conciente de consumir sua energia, uma comunidade que pensa no bolso e no meio ambiente.</div>
                                </v-card-text>
                            </div>
                            <!-- <div class="text-center pt-12 pb-12">
                                <v-icon color="white" class="text-center" x-large>mdi-arrow-down-circle-outline</v-icon>
                            </div> -->
                        </v-img>
                        <div pa-6 class="pa-6 pt-12 justify-center text-left" style="background:#efefef">
                            <v-container  grid-list-md>
                                <v-layout row wrap>
                                    <v-flex xs12>
                                        <h3 pt-1 >COMO FUNCIONA</h3>
                                    </v-flex>
                                    <v-flex xs2 lg2>
                                        <h1 class="blue--text">1.</h1>
                                    </v-flex>
                                    <v-flex xs10 lg10 pr-4 style="padding-top:20px">
                                        Avaliamos seu gasto médio anual e com isso determinamos a quantidade de energia (percentual) que você pode consumir de nossas usinas.
                                    </v-flex>
                                    <v-flex xs2 lg2 pt-6>
                                        <h1 class="blue--text">2.</h1>
                                    </v-flex>
                                    <v-flex xs10 lg10 pr-4 pt-6 style="padding-top:20px">
                                        Você assina a Enercred, garante sua vaga, e assim que houver uma usina disponivel enviamos seus dados para a Cemig.
                                    </v-flex>
                                    <v-flex xs2 lg2 pt-6>
                                        <h1 class="blue--text">3.</h1>
                                    </v-flex>
                                    <v-flex xs10 lg10 pr-4 pt-6 style="padding-top:20px">
                                        Assim que a Cemig fizer seu cadastro como membro da usina você passará a receber créditos na sua conta de energia, esses créditos são descontados do valor total da sua conta.
                                     </v-flex>
                                    <v-flex xs2 lg2 pt-6>
                                        <h1 class="blue--text">4.</h1>
                                     </v-flex>
                                    <v-flex xs10 lg10 pr-3 style="padding-top:20px">
                                        A Enercred mensalmente verifica a quantidade de energia que você recebeu de nossas usinas e gera uma fatura do valor devido com os descontos que você garantiu.
                                     </v-flex>
                                    <v-flex xs2 lg2 pt-2>
                                        <h1 class="blue--text">5.</h1>
                                     </v-flex>
                                    <v-flex xs10 lg10 pr-3 style="padding-top:20px">
                                        Você continua pagando para a Cemig, mas agora o valor mínimo que corresponde a taxa de distribuição da energia.
                                     </v-flex>
                                    <v-flex xs2 lg2 pt-2>
                                        <h1 class="blue--text">6.</h1>
                                     </v-flex>
                                    <v-flex xs10 lg10 pr-3 style="padding-top:20px">
                                        Mesmo com duas contas você passa a economizar mensalmente sem ter feito qualquer investimento, ajudando um montão o nosso planeta.
                                     </v-flex>
                                    <v-flex xs2 lg2>
                                        <h1 class="blue--text">7.</h1>
                                     </v-flex>
                                    <v-flex xs10 lg10 pr-3 style="padding-top:20px">
                                        Você conta a novidade para todos os seus amigos e familiares para que eles possam participar também.
                                     </v-flex>
                                    <v-btn outline block rounded @click="dialog_howworks = false" color="primary" class="mt-6 mb-6 justify-center">
                                        fechar
                                    </v-btn>
                                </v-layout>
                            </v-container>
                        </div>

                        <div pt12 pa2 pb12 pr-12 class="ma-2 pa-2 pt-12 pb-12 pr-12 justify-center text-left"  style="height=100vh" fill-height>
                            <div class="display-1 blue--text font-weight-regular">Sua energia limpa</div>
                            <!-- <v-icon x-large class="justify-center pt-2" color="blue darken-4">mdi-solar-power</v-icon> -->
                            <div class="headline font-weight-regular pr-12">
                                Economia de até 15% na sua conta de luz. 1 conta de luz a menos por ano sem dores de cabeça.
                            </div>
                            <v-img src="../img/howworks/money.png"/>
                        </div>
                        <div class="pa-4 pt-12 pb-12 pr-12 justify-center text-left  white--text" style="background:darkblue">
                            <!-- <v-icon x-large class="justify-center pt-2" color="blue darken-4">mdi-nature-people</v-icon> -->
                            <div class="display-1 yellow--text font-weight-regular pt-12 justify-center">Por um mundo mais sustentável</div>
                            <div class="headline font-weight-regular pr-12 pb-12 justify-center" >
                                Você entra para uma comunidade que economiza e ajuda o planeta, garantindo um futuro sustentavel para nós e nossas familias.
                            </div>
                        </div>
                        <v-img src="../img/howworks/solar.png" class="text-center justify-center"/>
                        <div class="pa-4 pt-0 pb-12 pr-12 justify-center text-left  black--text">
                            <!-- <v-icon x-large class="justify-center pt-2" color="blue darken-4">mdi-nature-people</v-icon> -->
                            <div class="display-1 blue--text darken-2 font-weight-regular pt-12 justify-center">Gerar sua própria energia</div>
                            <div class="headline font-weight-regular pr-12 pb-12">
                                Assinando nosso serviço você passa a alugar um percentual de nossas usinas e gerar sua própria energia limpa.
                            </div>
                        </div>
                        <div class="pa-6 pt-12 justify-center text-left">
                            <v-btn outlined block rounded @click="dialog_howworks = false" class="mt-6 mb-6 justify-center">
                                fechar
                            </v-btn>
                        </div>
                        
                    </v-card>
                </v-row>
            </v-container>
        </v-layout>
    </v-dialog>
</template>
<script>
import { eventBus } from "../main"

export default {
    mounted () {
        eventBus.$on('open-dialog_howworks', async () => {
            this.dialog_howworks = true
        })
    },
    data: () => ({
        dialog_howworks: false
    })
}
</script>