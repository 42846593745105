<template>
  <v-list two-line>
      <template v-for="(item, index) in itemsRandom">
          <v-list-tile
              style="border: 1px solid green; background-color: green; border-radius: 20px; width: 100%"
              :key="index"
              color="white"
              avatar
              @click="goToWhatsapp()"
          >
          <v-list-tile-avatar>
              <img :src="item.avatar">
          </v-list-tile-avatar>

          <v-list-tile-content>
              <v-list-tile-title v-html="item.title"></v-list-tile-title>
              <v-list-tile-sub-title style="color: white" v-html="item.subtitle"></v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
              <v-list-tile-avatar>
                  <img :src="item.icon">
              </v-list-tile-avatar>
          </v-list-tile-action>
          </v-list-tile>
      </template>
  </v-list>
</template>
<script>
export default {
  props: ['whatsapp_text'],
  name: "whatsappBtn",
  data: function() {
    return {
      items: [
        {
          // avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          avatar: '../img/avatar/raissa.png',
          icon: '../icons/whatsapp-verde.svg',
          title: this.whatsapp_text && this.whatsapp_text.text || 'Tire agora suas dúvidas',
          // subtitle: "<span class='text--white'>Ana Carolina</span> &mdash; Tire suas dúvidas com um de nossos especialistas",
          subtitle: this.whatsapp_text && this.whatsapp_text.subtext || "com um especialista",
          active: true
        },
        {
          // avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          avatar: '../img/avatar/alan.png',
          icon: '../icons/whatsapp-verde.svg',
          title: this.whatsapp_text && this.whatsapp_text.text || 'Fale agora',
          // subtitle: "<span class='text--white'>Ana Carolina</span> &mdash; Tire suas dúvidas com um de nossos especialistas",
          subtitle: this.whatsapp_text && this.whatsapp_text.subtext || "com consultor(a)",
          active: true
        },
        {
          // avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          avatar: '../img/avatar/erick.png',
          icon: '../icons/whatsapp-verde.svg',
          title: this.whatsapp_text && this.whatsapp_text.text || 'Fale agora',
          // subtitle: "<span class='text--white'>Ana Carolina</span> &mdash; Tire suas dúvidas com um de nossos especialistas",
          subtitle: this.whatsapp_text && this.whatsapp_text.subtext || "com um especialista",
          active: true
        },
        {
          // avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          avatar: '../img/avatar/ana.png',
          icon: '../icons/whatsapp-verde.svg',
          title: this.whatsapp_text && this.whatsapp_text.text || 'Tire agora suas dúvidas',
          // subtitle: "<span class='text--white'>Ana Carolina</span> &mdash; Tire suas dúvidas com um de nossos especialistas",
          subtitle: this.whatsapp_text && this.whatsapp_text.subtext || "com um especialista",
          active: true
        },
        {
          // avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          avatar: '../img/avatar/rodrigo.png',
          icon: '../icons/whatsapp-verde.svg',
          title: this.whatsapp_text && this.whatsapp_text.text || 'Tire agora suas dúvidas',
          // subtitle: "<span class='text--white'>Ana Carolina</span> &mdash; Tire suas dúvidas com um de nossos especialistas",
          subtitle: this.whatsapp_text && this.whatsapp_text.subtext || "com um especialista",
          active: true
        },
        {
          // avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          avatar: '../img/avatar/jose.png',
          icon: '../icons/whatsapp-verde.svg',
          title: this.whatsapp_text && this.whatsapp_text.text || 'Fale com a gente',
          // subtitle: "<span class='text--white'>Ana Carolina</span> &mdash; Tire suas dúvidas com um de nossos especialistas",
          subtitle: this.whatsapp_text && this.whatsapp_text.subtext || "no WhatsApp",
          active: true
        }
      ],
      itemsRandom: []
    }
  },
  created() {
    this.isProd = process.env.NODE_ENV === "production"

    if(this.items) {
      const idx = Math.floor(Math.random() * this.items.length);
      this.itemsRandom.push(this.items[idx])
    }
  },
  methods: {
    goToWhatsapp() {
        window.open('https://wa.me/5535998625580?text=Olá! :D')
    }
  }
}
</script>