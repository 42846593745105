import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {}
  },
  mutations: {
    storeUserState(state, user) {
      state.user = user;
    }
  },
  getters: {
    getUser: state => {

      if (!state.user.name) {
        return JSON.parse(localStorage.getItem("newUser"));
      } else {
        return state.user;
      }
    }
  }
});
