<template>
    <div class="py-0 px-3">
        <v-stepper class="elevation-0">
        <v-stepper-header>
            <template v-for="n in steps.steps">
                <v-stepper-step
                     color="teal"
                    :key="`${n}-step`"
                    :complete="steps.e1 >= n"
                    :step="n"
                    :editable="steps.e1 === n"
                >
                    <!-- {{ n }} -->
                </v-stepper-step>

                <v-divider
                    v-if="n !== steps.steps"
                    :key="n"
                ></v-divider>
            </template>
        </v-stepper-header>
        </v-stepper>
    </div>
</template>
<script>
export default {
    props: ['steps'],
}
</script>