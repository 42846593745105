<template>
  <div v-if="lead">
    <p>
      <strong>TERMOS E CONDIÇÕES</strong>
    </p>
    <p style="color:darkorange">
      <strong>
        AO ACEITAR ESSE CONTRATO, VOCÊ SE RESPONSABILIZA QUE AS INFORMAÇÕES SÃO VERDADEIRAS,
        ESTÁ CIENTE DO SEU COMPROMISSO COM A ENERCRED E QUE IRÁ REALIZAR OS PAGAMENTOS ASSIM QUE OS CRÉDITOS CHEGAR NA SUA CONTA DE ENERGIA.
      </strong>
    </p>
    <table class="c32">
      <tbody>
        <tr class="c24" >
          <td class="c0" colspan="3" rowspan="1">
            <p class="c15"><span class="c6">CONTRATO DE LOCAÇÃO DE FRAÇÃO DE USINA FOTOVOLTÁICA E OUTRAS AVENÇAS</span></p>
          </td>
        </tr>
        <tr class="c24">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c15"><span class="c6">DAS PARTES</span></p>
          </td>
        </tr>
        <tr class="c39">
          <td class="c9" colspan="1" rowspan="1" style="border:1px solid #c0c0c0; padding: 5px;">
            <p class="c17"><span class="c6">LOCADORA</span></p>
          </td>
          <td class="c25" colspan="2" rowspan="1" style="border:1px solid #c0c0c0; padding: 5px;">
            <p class="c10"><span class="c11">COOPERATIVA DE CONSUMIDORES DE ENERGIA ENERCRED COOP LTDA</span><span class="c19">, inscrita no CNPJ sob o n° </span><span class="c11">27.305.300/0001-35</span><span class="c19">, com sede na </span><span class="c11">Rua Cel. Machado, 290, Centro</span><span class="c19">, no Município de </span><span class="c11">Pedralva</span><span class="c19">, Estado de </span><span class="c11">MG</span><span class="c19">, CEP </span><span class="c11">37520-000</span><span class="c19">, neste ato representada na forma dos seus atos societários, doravante denominada </span><span class="c6">“LOCADORA”. </span></p>
            <p class="c10"><span class="c19">Para comunicações, contatar: </span><span class="c11">José Otavio Carneiro Bustamanete</span><span class="c19">, telefone </span><span class="c11">(31) 3500-3614</span><span class="c19">, e e-mail: </span><span class="c11">juridico@enercred.com.br</span></p>
          </td>
        </tr>
        <tr class="c33">
          <td class="c9" colspan="1" rowspan="1" style="border:1px solid #c0c0c0; padding: 5px;">
            <p class="c17"><span class="c6">LOCATÁRIA</span></p>
          </td>
          <td class="c25" colspan="2" rowspan="1">
            <p class="c10">
              <span class="c11">{{lead.name || ''}}</span>
              <span class="c19">, </span>
              <span class="c19 c12">brasileiro, </span>
              <span class="c19" v-if="lead.ID_document"> portador da identidade RG n° </span>
              <span class="c11" v-if="lead.ID_document">{{lead.ID_document}}</span>
              <span class="c19">,  inscrito no CPF sob o n° </span>
              <span class="c11">{{lead.cpf}}</span>
              <span class="c19" v-if="lead.address && lead.address.address">, endereço </span>
              <span class="c11" v-if="lead.address && lead.address.address">{{lead.address.address}} - {{lead.address.address_number}} {{lead.address.address_complement?' - '+lead.address.address_complement:''}}</span>
              <span class="c19" v-if="lead.address && lead.address.address">, no Município de </span>
              <span class="c11" v-if="lead.address && lead.address.address">{{lead.address.city}}</span>
              <span class="c19" v-if="lead.address && lead.address.address">, Estado de </span>
              <span class="c11" v-if="lead.address && lead.address.address">{{lead.address.state.initials}}</span>
              <span class="c19" v-if="lead.address && lead.address.address">, CEP </span>
              <span class="c11" v-if="lead.address && lead.address.address">{{lead.address.postal_code}}</span>
              <span class="c19">, doravante denominada</span>
              <span class="c30"> “LOCATÁRIA”</span>
              <span class="c5">.</span>
            </p>
            <p class="c10">
              <span class="c19">Para comunicações, contatar: </span>
              <span class="c11">{{lead.name || lead.name}}</span>
              <span class="c19">, telefone </span>
              <span class="c11">{{lead.phone}}</span>
              <span class="c19">, e e-mail: </span>
              <span class="c11">{{lead.email}} </span>
            </p>
          </td>
        </tr>
        <tr class="c23">
          <td class="c29" colspan="3" rowspan="1">
            <p class="c17"><span class="c6"> </span></p>
          </td>
        </tr>
        <tr class="c38">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c15"><span class="c6">DO OBJETO E CONDIÇÕES COMERCIAIS</span></p>
          </td>
        </tr>
        <tr class="c23">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c3"><span class="c6">Objeto: </span><span class="c6 c12" style="font-weight: bold; background-color: yellow">locação de {{percentual}}%</span><span class="c6"> da Potência Total da UFV</span><span class="c6">, equivalente a aproximadamente </span><span class="c6 c12" style="font-weight: bold; background-color: yellow">{{kWh}} kWp</span><span class="c6">.</span></p>
          </td>
        </tr>
        <tr class="c23">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c3"><span class="c6">Concessionária de Energia Local: </span><span class="c6 c12">CEMIG</span></p>
          </td>
        </tr>
        <tr class="c23">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c3"><span class="c6">Período de Locação: </span><span class="c6 c12" style="font-weight: bold; background-color: yellow">3(três) meses</span><span> contados a partir do recebimento dos créditos de energia na conta da CEMIG.</span></p>
          </td>
        </tr>
        <tr class="c23">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c3"><span class="c6">Multa por Resolução por Inadimplemento: </span><span class="c5 c12">30%</span><span class="c5"> do valor total remanescente do Contrato </span></p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c1" colspan="3" rowspan="1">
            <p class="c17"><span class="c30" style="font-weight: bold">Aluguel mensal: </span><span class="c19"></span><span class="c11" style="font-weight: bold; background-color: yellow">R$ {{aluguel}} </span>
            <!-- <span class="c19">(</span><span class="c11">[•]</span><span class="c19">) por mês.</span><span class="c6"> </span> -->
            </p>
          </td>
          <!-- <td class="c1" colspan="1" rowspan="1">
            <p class="c17"><span class="c30">Valor da cota: </span><span class="c19">R$</span><span class="c11">({{lead.creditos/lead.percentage}})</span><span class="c19"> (</span><span class="c11">[•]</span><span class="c19">) por mês.</span></p>
          </td> -->
        </tr>
        <tr class="c4">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c17"><span class="c30">Reajuste do aluguel:</span><span class="c19"> mesmos dias de faturamento da concessionaria </span><span class="c19">. </span></p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c29" colspan="3" rowspan="1">
            <p class="c17 c20"><span class="c6"></span></p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c15"><span class="c6">DO FATURAMENTO</span></p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c1" colspan="3" rowspan="1">
            <p class="c17"><span class="c30">Vencimento do Aluguel: </span><span class="c11" style="font-weight: bold">(2) (Dois dias)</span><span> antes do vencimento da fatura da Cemig</span><span class="c6"></span></p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c1" colspan="3" rowspan="1">
            <p class="c17"><span class="c30">Forma de Pagamento: </span><span class="c11">{{lead.payment_type==='boleto'?'Boleto':'Cartão de crédito'}}</span></p>
          </td>
        </tr>
        <!-- <tr class="c4">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c17"><span class="c30">Dados da LOCADORA para faturamento: </span><span class="c11">[•]</span><span class="c30"> inscrita no CNPJ sob nº </span><span class="c11">[•]</span><span class="c30">, e com endereço </span><span class="c11">[•]</span></p>
          </td>
        </tr> -->
        <!-- <tr class="c4">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c17"><span class="c6">Dados Bancários para Pagamento:</span></p>
            <p class="c17"><span class="c19">Banco: </span><span class="c11">[•]</span><span class="c19">; Agência: </span><span class="c11">[•]</span><span class="c19">; Conta Corrente </span><span class="c11">[•]</span><span class="c19">: </span></p>
          </td>
        </tr> -->
        <tr class="c4">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c17"><span class="c30">Multa por Mora do Pagamento: </span><span class="c19 c12">02% (dois por cento) do valor inadimplido</span></p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c17"><span class="c30">Juros por Mora do Pagamento:</span><span class="c19"> </span><span class="c19 c12">01% (um por cento) ao mês calculados sobre o valor inadimplido e correção monetária calculada com base no IPCA “pro rata die”</span><span class="c19"> </span></p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c29" colspan="3" rowspan="1">
            <p class="c17 c20"><span class="c6"></span></p>
          </td>
        </tr>
        <tr class="c26" v-if="lead.installation_numbers.length > 0">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c15"><span class="c6">DO SISTEMA DE COMPENSAÇÃO</span></p>
          </td>
        </tr>
        <tr class="c4" v-if="lead.installation_numbers.length > 0">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c15"><span class="c6">Nº da(s) Unidade(s) Consumidora(s) da Locatária</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1" v-if="lead.installation_numbers.length === 1">
            <p class="c15"><span class="c6">Percentual de Locação</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1" v-else>
            <p class="c15"><span class="c6"></span></p>
          </td>
        </tr>
        <tr class="c4" v-for="(uc, index) in lead.installation_numbers" :key="index">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c15">
              <span class="c11">{{uc.text}}</span>
              <!-- <span class="c30"> </span>
              <span class="c19">(</span><span class="c11">[•]</span><span class="c19">)</span> -->
            </p>
          </td>
          <td class="c1" colspan="1" rowspan="1" v-if="lead.installation_numbers.length === 1">
            <p class="c15">
              <span class="c11">{{percentual}}%</span>
              <!-- <span class="c30"> </span> -->
              <!-- <span class="c19">(</span><span class="c11">[•]</span><span class="c19">)</span> -->
            </p>
          </td>
          <td class="c1" colspan="1" rowspan="1" v-else>
            <p class="c15"></p>
          </td>
          <v-divider
            v-if="index -1 <= lead.installation_numbers.length"
            :key="index"
          ></v-divider>
        </tr>
        <!-- <tr class="c4">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c15"><span class="c11">[•]</span><span class="c30"> </span><span class="c19">(</span><span class="c11">[•]</span><span class="c19">)</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c15"><span class="c11">[•]</span><span class="c30"> </span><span class="c19">(</span><span class="c11">[•]</span><span class="c19">)</span></p>
          </td>
        </tr> -->
        <tr class="c4">
          <td class="c29" colspan="3" rowspan="1">
            <p class="c15 c20"><span class="c6"></span></p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c15"><span class="c6">DO MANDATO:</span></p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c0" colspan="3" rowspan="1">
            <p class="c10"><span class="c19">Em conformidade com o art. 684 do Código Civil, a LOCATÁRIA outorga à LOCADORA, de forma irrevogável e irretratável, durante a vigência deste Contrato, sendo revogados após sua rescisão ou término, poderes gerais necessários para que a LOCADORA a represente em todo e qualquer assunto junto à Concessionária de Energia Local para fins de inclusão da LOCATÁRIA como beneficiária de geração compartilhada e também para ter acesso e poder consultar livremente suas faturas de energia perante a Concessionária de Energia Local. O presente Mandato poderá ter sua vigência estendida por até 60 (sessenta) meses ou novo prazo que venha a ser estabelecido pelo art. 6º, §1º da REN ANEEL n.º 482/2012, após o fim do Período de Locação, caso a LOCATÁRIA possua créditos em quantidade de energia ativa a ser consumida após a rescisão ou término deste Contrato. </span></p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="c2"><span class="c5"></span></p>
    <p class="c10"><span class="c5">A LOCADORA e a LOCATÁRIA, em conjunto referidas como “Partes” e individualmente como “Parte”, resolvem celebrar o presente CONTRATO DE LOCAÇÃO DE FRAÇÃO DE USINA FOTOVOLTAICA E OUTRAS AVENÇAS (“Contrato”), que se regerá de acordo com os arts. 565 e seguintes do Código Civil, pelas condições acima previstas e pelas disposições de seu Anexo I, o “Regulamento e Condições Gerais de Locação de Fração de Usina Fotovoltaica” (“Regulamento”).</span></p>
    <p class="c2"><span class="c5"></span></p>
    <p class="c15" style="text-align: center"><span class="c11">{{new Date | formatDate}}</span></p>
    <br><br>
    <hr size=1/>
    <p class="c17 c20"><span class="c28"></span></p>
    <p class="c15"><span class="c6">ANEXO I</span></p>
    <p class="c2"><span class="c6"></span></p>
    <p class="c15 c22"><span class="c6">REGULAMENTO E CONDIÇÕES GERAIS DE LOCAÇÃO DE FRAÇÃO DE USINA FOTOVOLTAICA </span></p>
    <p class="c2"><span class="c6"></span></p>
    <p class="c10"><span class="c6">CONSIDERANDO QUE:</span></p>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_2-0 start" start="1">
      <li class="c10 c14"><span class="c5">LOCADORA e LOCATÁRIA firmaram o CONTRATO DE LOCAÇÃO DE FRAÇÃO DE USINA FOTOVOLTAICA E OUTRAS AVENÇAS (“Contrato”);</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_2-0" start="2">
      <li class="c10 c14"><span class="c5">A LOCATÁRIA é consumidora de energia elétrica e, visando a redução de custos, optou por participar do Sistema de Compensação de Energia Elétrica (SCEE) criado pela Agência Nacional de Energia Elétrica (“ANEEL”) por meio da Resolução Normativa 482/2012;</span></li>
    </ol>
    <p class="c17 c20"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_2-0" start="3">
      <li class="c10 c14"><span class="c5">A LOCADORA, obedecendo todas as normas e resoluções da ANEEL, possui miniusina solar fotovoltaica (Usina) devidamente enquadrada nos termos da Resolução Normativa 482/2012 e tem interesse em alugar fração dela; </span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_2-0" start="4">
      <li class="c10 c14"><span class="c5">Este REGULAMENTO E CONDIÇÕES GERAIS DE LOCAÇÃO DE FRAÇÃO DE USINA FOTOVOLTAICA (“Regulamento”) é anexo e parte integrante e indissociável do Contrato; </span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <p class="c10"><span class="c5">As Partes, identificadas e qualificadas no Contrato, têm entre si justo e contratado o que segue neste Regulamento, em adição às disposições do Conrato:</span></p>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-0 start" start="1">
      <li class="c10 c14"><span class="c8">OBJETO</span></li>
    </ol>
    <p class="c2"><span class="c6"></span></p>
    <ol class="c7 lst-kix_list_4-1 start" start="1">
      <li class="c10 c14"><span class="c5">O presente Regulamento tem por objeto estabelecer os termos e condições gerais aplicáveis à locação de fração ideal da Usina pela LOCADORA, na qualidade de cooperada da </span><span class="c5 c12">[COOPERATIVA]</span><span class="c5">, à LOCATÁRIA, pelo prazo estabelecido no Contrato, de forma a viabilizar a compensação de energia elétrica nos termos da Resolução Normativa 482/2012.</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-2 start" start="1">
      <li class="c10 c14"><span class="c5">A Usina destina-se a permitir a compensação de créditos de energia elétrica nas contas de energia da Unidade Consumidora da LOCATÁRIA que se encontra na área da concessionária de energia do local da Usina, conforme indicado no Contrato, mediante geração compartilhada, nos termos e percentuais definidos no Contrato, ficando desde logo ajustado que não haverá transferência da posse da Usina à LOCATÁRIA, permanecendo a LOCADORA como responsável pela posse, operação e manutenção integral da Usina, garantido, contudo, o uso e o gozo da fração da Usina em benefício da LOCATÁRIA., nos termos do art. 566 do Código Civil</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="2">
      <li class="c10 c14"><span class="c5">Todas as definições e/ou referências previstas no Contrato são aplicáveis ao presente Regulamento e vice-versa, sendo entendidas como definições e/ou referências os termos e expressões identificados entre aspas e com a primeira ou todas as letras maiúsculas. </span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-0" start="2">
      <li class="c10 c14"><span class="c8">VIGÊNCIA</span></li>
    </ol>
    <p class="c2"><span class="c6"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="3">
      <li class="c10 c14"><span class="c5">As disposições deste Regulamento perdurarão pelo Período de Locação do Contrato, ficando desde já certo que, ao final do Período de Locação, o Contrato estará automaticamente renovado por igual período, podendo qualquer das Partes manifestar o interesse na sua denúncia unilateral e imotivada, nos termos previstos neste Regulamento. </span></li>
    </ol>
    <p class="c17 c20"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-2" start="2">
      <li class="c10 c14"><span class="c5">Qualquer das Partes poderá denunciar unilateramente e imotivadamente o Contrato, sem multa, mediante notificação de seu interesse à outra Parte com antecedência mínima de </span><span class="c5 c12">90 (noventa)</span><span class="c5"> dias ao final do Período de Locação. O desrespeito ao prazo de aviso prévio antes referido constituirá inadimplemento contratual. </span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-2" start="3">
      <li class="c10 c14"><span class="c5">As Partes desde já acordam que, em nenhuma hipótese, ao final do Contrato, a Usina será alienada ou, de qualquer forma, transferida para a propriedade da LOCATÁRIA.</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-0" start="3">
      <li class="c10 c14"><span class="c8 c12">CONDIÇÕES RESOLUTIVAS</span></li>
    </ol>
    <p class="c2"><span class="c5 c12"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="4">
      <li class="c10 c14"><span class="c5 c12">Constituem condições resolutivas as que, se verificadas, qualquer uma delas, a qualquer tempo, encerram a relação contratual, sem necessidade de pagamento de multa, indenização ou ressarcimento de qualquer forma de uma Parte à outra, ressalvado o valor do Aluguel Mensal devido até então:</span></li>
    </ol>
    <p class="c10"><span class="c5 c12">        </span></p>
    <ol class="c7 lst-kix_list_6-0 start" start="1">
      <li class="c10 c14"><span class="c5 c12">A não obtenção da aprovação de conexão da Usina à rede de distribuição pela Concessionária de Energia Local em até 06 (seis) meses após a assinatura do Contrato, aplicando-se o mesmo a eventuais renovações, desde que a incapacidade de renovação não seja advinda da negligência da LOCADORA; e/ou</span></li>
    </ol>
    <p class="c2"><span class="c5 c12"></span></p>
    <ol class="c7 lst-kix_list_6-0" start="2">
      <li class="c10 c14"><span class="c5 c12">A não obtenção da licença operação (ou equivalente), no prazo máximo de 06 (seis) meses, contados da assinatura do Contrato, o mesmo aplicando-se às suas eventuais renovações, desde que a incapacidade de renovação não seja advinda da negligência ou imperícia da LOCADORA.</span></li>
    </ol>
    <p class="c2"><span class="c5 c12"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="5">
      <li class="c10 c14"><span class="c5 c12">A LOCADORA se compromete a envidar os seus melhores esforços para que as condições relacionadas ao objeto do Contrato sejam implementadas nos prazos estipulados. </span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-0" start="4">
      <li class="c10 c14"><span class="c8">VALOR DA LOCAÇÃO</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="6">
      <li class="c10 c14" id="h.gjdgxs"><span class="c5">Cada cota da Usina possui o valor para locação conforme indicado no Contrato, podendo a LOCATÁRIA optar pela locação de quantas cotas julgue necessárias para atender a seu perfil de consumo de energia elétrica, desde que previamente aprovado pela LOCADORA. </span></li>
    </ol>
    <p class="c2 c37"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="7">
      <li class="c10 c14"><span class="c5">O valor mensal da locação devido regularmente pela LOCATÁRIA à LOCADORA é o previsto no Contrato, sendo resultado do valor individual de cada quota multiplicado pelo número de quotas locadas. De forma extraordinária, os valores previstos no preâmbulo do Contrato a título de “Aluguel Mensal” e “Valor da Quota” poderão, sofrer redução em determinado mês durante o Período da Locação, em virtude das apurações e análises feitas pela LOCADORA no tocante ao funcionamento da Usina, aos dias de manutenção que se fizeram necessários, às condições climatológicas e outros fatores que possam ter impactado o bom funcionamento dos equipamentos. Nesse sentido, fica estabelecido entre as Partes que os valores previstos no Contrato serão sempre o máximo debitável da LOCATÁRIA mensalmente. Eventual concessão de desconto em virtude da apuração de tais critérios não representará, em nenhuma hipótese, a alteração definitiva dos termos do Contrato e, ainda, não será, necessariamente, concedida nos meses subsequentes. </span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="8">
      <li class="c10 c14"><span class="c5"> O valor será atualizado pela Inflação Energética, que consiste a variação percentual positiva ou negativa da Tarifa de Energia do subgrupo B1 da concessionária de energia local entre o período de reajuste anterior e o atual, conforme publicado pela ANEEL. O reajuste ocorrerá sempre na </span><span class="c5">data de Reajuste do aluguel</span><span class="c5">, conforme previsto no Contrato. Caso, por qualquer motivo, tal índice deixe de existir, ele será substituído por outro que venha a sucedê-lo. Na ausência de novo índice, deverão as Partes negociar o novo índice a ser aplicado em até 30 (trinta) dias.</span></li>
    </ol>
    <p class="c17 c20"><span class="c5 c12"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="9">
      <li class="c10 c14"><span class="c5 c12">As Partes concordam que todos os riscos, custos, obrigações e responsabilidades inerentes ao correto funcionamento da Usina estão incluídos no valor da locação, que já considera eventuais perdas de energia na rede de distribuição da LOCATÁRIA e da Concessionária de Energia Local.</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="10">
      <li class="c10 c14"><span class="c5">Quaisquer alterações nos percentuais das alíquotas dos tributos incidentes sobre o Contrato, bem como a criação, extinção, modificação, isenção, redução desses tributos, direitos, encargos de qualquer espécie, instituição de estímulos fiscais de qualquer natureza e eventuais reajustes extraordinários por parte da Concessionária de Energia Local, que de qualquer forma incidam ou venham a impactar o valor da locação, ocorridos entre a data de assinatura do Contrato e a data do respectivo faturamento, ensejarão a revisão do aluguel a fim de adequá-lo às modificações havidas. </span></li>
    </ol>
    <p class="c2 c21"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-0" start="5">
      <li class="c10 c14"><span class="c8">DO FATURAMENTO E DA FORMA DE PAGAMENTO</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="11">
      <li class="c10 c14"><span class="c5 c12">A fatura explicativa da cobrança será encaminhada à LOCATÁRIA mensalmente, até o </span><span class="c5 c12">15º (décimo quinto) </span><span class="c5 c12">dia corrido de cada mês, e terá como data de vencimento e forma de pagamento as indicadas no Contrato.</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-2" start="4">
      <li class="c10 c14"><span class="c5">A cobrança do aluguel só será devida a partir do momento em que a LOCATÁRIA passar a usufruir dos benefícios da locação da quota-parte da Usina. </span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="12">
      <li class="c10 c14"><span class="c5">O atraso no envio do documento de cobrança pela LOCADORA postergará todos os prazos relativos a faturamento e pagamento previstos no Contrato, não podendo tal atraso ser alegado como inadimplência da LOCATÁRIA ou ensejar pagamento de qualquer penalidade ou indenização. </span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="13">
      <li class="c10 c14"><span class="c5">A LOCADORA será a responsável pelo recolhimento de todos os tributos que incidam ou venham a incidir sobre as atividades inerentes à execução do objeto do Contrato, não cabendo qualquer obrigação à LOCATÁRIA com relação a estes, exceto quanto às obrigações cujo cumprimento, por imposição legal, seja de responsabilidade da LOCATÁRIA. </span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="14">
      <li class="c10 c14"><span class="c5">Fica caracterizada a mora da LOCATÁRIA quando deixar de liquidar quaisquer das cobranças devidas, nos termos do Contrato e deste Regulamento, de forma integral, até a data do vencimento. No caso de mora no pagamento, incidirão sobre os valores inadimplidos os acréscimos de correção monetária, multa e juros de mora calculados “pro rata die”, tal como previstos no Contrato.</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-0" start="6">
      <li class="c10 c14"><span class="c8">DAS OBRIGAÇÕES DAS PARTES</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="1">
      <li class="c10 c14"><span class="c5">Sem prejuízo das demais disposições previstas no Contrato, são obrigações da LOCADORA:</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_8-0 start" start="1">
      <li class="c10 c14"><span class="c5">a) Observar e cumprir rigorosamente toda a legislação vigente e aplicável ao objeto do Contrato;</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_8-0" start="1">
      <li class="c10 c14"><span class="c5">b) Obter e manter válidas e vigentes, durante toda a vigência do Contrato, todas as licenças necessárias para implantação, operação e exploração da Usina, bem como todas aquelas necessárias ao cumprimento das obrigações assumidas no Contrato;</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_8-0" start="1">
      <li class="c10 c14"><span class="c5">c) Garantir o pleno funcionamento da Usina em condições técnicas de operação, de forma a permitir seu aproveitamento na modalidade de geração compartilhada em favor da LOCATÁRIA;</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_9-1 start" start="2">
      <li class="c10 c36"><span class="c5">Sem prejuízo das demais disposições previstas no Contrato, são obrigações da LOCATÁRIA:</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_11-0 start" start="2">
      <li class="c10 c14"><span class="c5">a) Pagar pontualmente o valor devido pela locação da Usina, conforme previsto no Contrato;</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_11-0" start="2">
      <li class="c10 c14"><span class="c5">b) Informar eventuais alterações da unidade consumidora sempre que tais alterações impactarem no objeto do Contrato, com no mínimo </span><span class="c5 c12">120 (cento e vinte)</span><span class="c5"> </span><span class="c5">dias de antecedência. Fica a LOCATÁRIA ciente de que a inobservância dessa comunicação no prazo retro previsto poderá acarretar a perda de créditos de energia que lhe seriam destinados; </span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_11-0" start="2">
      <li class="c10 c14"><span class="c5">c) Se abster de influenciar, direta ou indiretamente, no funcionamento e operação da Usina.</span></li>
    </ol>
    <p class="c2 c21"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-0" start="7">
      <li class="c10 c14"><span class="c8">CASO FORTUITO E FORÇA MAIOR</span></li>
    </ol>
    <p class="c2"><span class="c8"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="1">
      <li class="c10 c14"><span class="c5">Nos termos do artigo 393 do Código Civil Brasileiro, as obrigações de cada uma das Partes não serão exigíveis durante a ocorrência comprovada de caso fortuito e/ou de força maior, assim considerado qualquer ato, evento ou circunstância inevitável e imprevisível não causado por erro, culpa ou dolo de qualquer das Partes, de seus empregados, funcionários, prepostos, subcontratados ou fornecedores e cujos efeitos não possam ser superados pelo exercício de esforços imediatos e diligentes. </span></li>
    </ol>
    <p class="c2 c31"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="2">
      <li class="c10 c14"><span class="c5">A Parte afetada pelo evento de caso fortuito ou de força maior deverá tomar as medidas possíveis para minimizar seus efeitos, bem como notificar a outra Parte quando se tornar razoavelmente aparente que a execução do Contrato sofrerá qualquer tipo de atraso, mas nunca após o prazo de 5 (cinco) dias contados da materialização do evento, com as informações necessárias à caracterização das circunstâncias, tais como: tipo de evento, horário de sua ocorrência, sua exata localização, efeitos e consequências sobre as obrigações das Partes e providências corretivas imediatamente tomadas.</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="3">
      <li class="c10 c14"><span class="c5">Na ocorrência de fatos ou eventos de caso fortuito ou força maior, cada uma das Partes arcará com todos os prejuízos diretos e indiretos dele resultantes, somente sendo permitida a extinção do presente Contrato, nos termos da Cláusula 8, abaixo. </span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-0" start="8">
      <li class="c10 c14"><span class="c8">DA EXTINÇÃO, DAS PENALIDADES E DA RESPONSABILIDADE</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="1">
      <li class="c10 c14"><span class="c5">O Contrato poderá ser resolvido, sem qualquer penalidade ou ônus, quando:</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_13-0 start" start="1">
      <li class="c10 c14"><span class="c5 c12">a) Verificada qualquer uma das Condições Resolutivas previstas neste Regulamento;</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_13-0" start="1">
      <li class="c10 c14"><span class="c5">b) Houver impossibilidade de manutenção do modelo de compensação de crédito de energia elétrica em razão de alteração em leis, resoluções ou qualquer outra norma superveniente;</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_13-0" start="1">
      <li class="c10 c14"><span class="c5">c) Ocorrer evento de caso fortuito ou de força maior, devidamente comprovado, impeditivo de execução do Contrato por mais de 180 (cento e oitenta) dias, nos termos da Cláusula 7;</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="2">
      <li class="c10 c14"><span class="c5">O Contrato poderá ser resolvido por inadimplemento, com a aplicação de multa compensatória, em caso de:</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_15-0 start" start="2">
      <li class="c10 c14"><span class="c5">a) Inadimplemento das obrigações de qualquer das Partes, não sanadas no prazo máximo de </span><span class="c5 c12">60 (sessenta) dias</span><span class="c5">, a contar do recebimento pela Parte inadimplente de notificação, por escrito, enviada pela Parte adimplente, instando-a a adimplir a obrigação;</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_15-0" start="2">
      <li class="c10 c14"><span class="c5">b) Não renovação ou prorrogação da permissão perante à Concessionária de Energia Local e/ou das licenças ambientais perante os órgãos ambientais competentes em decorrência de culpa exclusiva da LOCADORA;</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="3">
      <li class="c10 c14"><span class="c5">Em qualquer hipótese de encerramento do Contrato, caberá à LOCADORA requerer o imediato desligamento da Unidade Consumidora da LOCATÁRIA do Sistema de Compensação de Energia Elétrica da Usina junto à Concessionária de Energia Local.</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="4">
      <li class="c10 c14"><span class="c5">A extinção do Contrato, independentemente do motivo, não isenta as Partes das obrigações devidas até a data da extinção, incluindo valores a título de aluguel da Usina vencidos ou a vencer até o exato momento da extinção.          </span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-0" start="9">
      <li class="c10 c14"><span class="c8">DAS DISPOSIÇÕES GERAIS</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="1">
      <li class="c10 c14"><span class="c5">As Partes declaram e garantem, para todos os fins e efeitos:</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_17-0 start" start="1">
      <li class="c10 c14"><span class="c5">a) Ter ciência das obrigações, responsabilidades e riscos assumidos em razão da celebração e execução do Contrato, reconhecendo-os como manifestamente proporcionais;</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_17-0" start="1">
      <li class="c10 c14"><span class="c5">b) Ter obtido e avaliado criteriosamente todas as informações e circunstâncias necessárias e convenientes relativas a contingências que possam influenciar ou afetar, de qualquer forma, a execução do objeto do Contrato.</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="2">
      <li class="c10 c14"><span class="c5">Este Regulamento e o Contrato constituem o entendimento integral das Partes, prevalecendo sobre todas e quaisquer correspondências, entendimentos ou outros documentos anteriores firmados relativos ao mesmo objeto, vinculando as Partes e obrigando seus eventuais respectivos sucessores. </span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-2" start="2">
      <li class="c10 c14"><span class="c5">9.2.1. A LOCADORA poderá ceder ou transferir sua posição no Contrato ou quaisquer benefícios, interesses, direitos e/ou obrigações dele decorrentes, no todo ou em Parte, independentemente de anuência prévia da outra LOCATÁRIA.</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-2" start="6">
      <li class="c10 c14"><span class="c5">A LOCATÁRIA somente poderá ceder ou transferir sua posição no Contrato ou quaisquer benefícios, interesses, direitos e/ou obrigações dele decorrentes, no todo ou em parte, caso obtenha anuência prévia e por escrito da LOCADORA.</span></li>
    </ol>
    <p class="c2 c21"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="25">
      <li class="c10 c14"><span class="c5">A nulidade declarada de qualquer uma das cláusulas ou condições ora pactuadas não acarretará a nulidade do Contrato, que permanecerá válido e exigível em todos os seus demais termos e condições. </span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="2">
      <li class="c10 c14"><span class="c5">9.2.2. Não haverá nenhum relacionamento entre as Partes de qualquer natureza (trabalhista, societária ou outra) que não o contemplado pelo Contrato e respectivo Regulamento. Nenhuma delas tem qualquer direito, poder ou autoridade de assumir qualquer obrigação ou responsabilidade em nome ou por conta da outra.</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="3">
      <li class="c10 c14"><span class="c5">Qualquer aviso ou comunicação de uma Parte à outra será feita por escrito e poderá ser entregue por Correios, correio eletrônico (e-mail) ou cartório. Em qualquer caso, sempre com prova formal do seu recebimento, nos endereços indicados no Contrato.</span></li>
    </ol>
    <p class="c17 c20"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="4">
      <li class="c10 c14"><span class="c5">Caso haja mudança posterior na legislação e normas do setor elétrico brasileiro, inclusive nos Procedimentos de Distribuição - PRODIST ou na Resolução Normativa nº 482, de 17/04/2012, que venham alterar substancialmente as condições ora pactuadas, as Partes desde já concordam em negociar de boa-fé o seu aditamento, visando à manutenção das bases objetivas do Contrato, sendo certo que, na impossibilidade de acordo para preservá-lo, poderá ser resolvido, sem quaisquer ônus ou multas, por qualquer das Partes, nos termos da Cláusula 8 deste Contrato.</span></li>
    </ol>
    <p class="c2 c21"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="5">
      <li class="c10 c14"><span class="c5">O Contrato constitui título executivo extrajudicial, na forma do artigo 784, insiso III, do Código de Processo Civil, inclusive para efeito de cobrança de todos e quaisquer valores decorrentes das obrigações contempladas no Contrato e/ou no Regulamento, abrangendo aluguel, ressarcimento dos valores já desembolsados, encargos financeiros, multas, juros moratórios e remuneratórios, entre outros.</span></li>
    </ol>
    <p class="c2"><span class="c5"></span></p>
    <p class="c2"><span class="c5"></span></p>
    <p class="c2"><span class="c5"></span></p>
    <ol class="c7 lst-kix_list_4-1" start="6">
      <li class="c10 c14"><span class="c5">O Contrato e este Regulamento são regidos e interpretados pela legislação brasileira e quaisquer disputas deles decorrentes serão dirimidas no foro da comarca de São Paulo, Estado de São Paulo, o qual prevalecerá sobre qualquer outro, por mais privilegiado que seja.</span></li>
    </ol>
    <p>
      <br />
      <br />FIM
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </p>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: ['lead'],
  computed: {
    percentual() {
      if(this.lead.average_consumption && Number(this.lead.average_consumption)) {
        return (((this.lead.average_consumption * (1 - 0.09) - 50) / 160000) * 100).toFixed(2).replace('.',',')
      } else {
        return null
      }
    },
    kWh() {
      if(this.lead.average_consumption && Number(this.lead.average_consumption)) {
        return (((this.lead.average_consumption) * (1 - 0.09) - 50)).toFixed(0)
      } else {
        return null
      }
    },
    aluguel() {
      if(this.lead.average_consumption && Number(this.lead.average_consumption)) {
        return (((this.lead.average_consumption) * (1 - 0.09) - 50)  * 0.85).toFixed(2).replace('.',',')
      } else {
        return null
      }
    }
  },
  filters: {
    formatCurrency(value) {
      // console.log('value: ', value)
      if(!value) return '0,00'
      else {
        return value.toFixed(2).toString().replace('.',',')
      }
    },
    formatDate(value) {
      if(!value) return ''
      return moment(value).format('DD/MM/YYYY HH:mm:ss')
    }
  }
}
</script>

<style scoped>
.c11 {
  font-weight: 400;
}
.c6{
  font-weight: bold;
}
</style>