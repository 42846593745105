import { render, staticRenderFns } from "./Faq.vue?vue&type=template&id=0a888925&scoped=true&"
import script from "./Faq.vue?vue&type=script&lang=js&"
export * from "./Faq.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a888925",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/Users/rodri/OneDrive/Documentos/GitHub/enercred-onboard/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VExpansionPanel } from 'vuetify/lib'
import { VExpansionPanelContent } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
installComponents(component, {VCard,VCardText,VExpansionPanel,VExpansionPanelContent,VIcon})
