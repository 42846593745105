<template>
  <div id="simulator_card">
    <img
      src="../../assets/img/save-money.jpg"
      width="300px"
    />
    <div class="simulator_card_info">
      <div style="padding: 10px">Aproximadamente</div>
      <div id="simulator_result_value">{{(lead.average_consumption * (1 - 0.09) - 50) * 0.10 * 12 | formatarMoeda}}</div>
      <div style="padding: 10px">de economia por ano</div>
      <div id="simulator_result_mensal" style="color:#666; font-size: 0.8rem">{{(lead.average_consumption * (1 - 0.09) - 50) * 0.10 | formatarMoeda}} por mês</div>
    </div>
    <div style="background: #e3e3e3; padding: 15px">Recebe em média <span style='font-weight:800; color: teal'>{{((lead.average_consumption * 1) * (1 - 0.09) - 50) | formatarMoeda}} </span> em créditos na sua conta de luz e paga apenas {{((lead.average_consumption) * (1 - 0.09) - 50) * 0.95 | formatarMoeda}}!</div>
  </div>
  <!-- <div>
    <div class="pb-2 pt-4 px-3" style="background-color: #efefef; border-radius: 10px">
      <div class="headline tertiary--text">Somente Cemig <small style="font-size: 12px">(sem Enercred)</small></div>

      <div class="order">
        <table width="100%" style="color:red; width:100%">
          <tr>
            <td style="width:70%">Você gasta em média</td>
            <td style="width:30%">R$ {{ lead.average_consumption * 1 | formatarMoeda }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="pb-2 pt-1 px-3" style="background-color: #fff; border-radius: 10px">
      <div class="headline pt-5 pb-3 text-primary text--darken-3 ">Com a Enercred</div>

      <div class="order">
        <v-data-table :items="items" hide-actions hide-headers>
          <template v-slot:items="props">
            <td :class="`pl-3 ${ props.item.color }`"  style="width:70%">
              <p v-html="props.item.name"></p>
              <v-tooltip bottom v-if="props.item.tooltip">
                <template v-slot:activator="{ on }">
                  <v-icon color="tertiary" dark slot="end" v-on="on">help</v-icon>
                </template>
                <span>{{props.item.tooltip}}</span>
              </v-tooltip>
            </td>
            <td  style="width:30%"
              :class="`pr-3 text-xs-right ${ props.item.color }`"
            >
              R$ {{ props.item.value | formatarMoeda }}
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </div> -->
</template>
<script>
export default {
  props: ["lead"],
  data() {
    return {
      headers: [
        {
          text: "Economia",
          align: "left",
          value: "name"
        }
      ],
      items: [
        {
          name: "Recebe <span style='font-weight:800; color: teal'> R$" + ((this.lead.average_consumption * 1) * (1 - 0.09) - 50).toFixed(2).replace(".", ",") + "</span> em créditos na sua conta de luz e paga apenas*",
          // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao)
          value: ((this.lead.average_consumption) * (1 - 0.09) - 50) * 0.9,
          color: "white lighten-3"
        },
        {
          name: "Continua pagando para a Cemig",
          // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao) * 90%
          value: (this.lead.average_consumption * 1) - (this.lead.average_consumption * (1 - 0.09) - 50),
          color: "white lighten-3",
          tooltip: "As normas brasileiras de energia obrigam que você pague um valor mínimo para a concessionária."
        },
        {
          name: "Agora você gasta menos<p><small>Cemig + Enercred<small></p>",
          // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao) * 90%
          value: ((this.lead.average_consumption * (1 - 0.09) - 50) * 0.9) + (this.lead.average_consumption - (this.lead.average_consumption * (1 - 0.09) - 50)),
          color: "white lighten-3"
        },
        {
          name: "Economia Mensal",
          // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao) * 10%
          value: (this.lead.average_consumption * (1 - 0.09) - 50) * 0.1,
          color: "teal lighten-3 font-weight-bold"
        },
        {
          name: "Economia Anual",
          // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao) * percentual_desconto * 12;
          value: (this.lead.average_consumption * (1 - 0.09) - 50) * 0.1 * 12,
          color: "teal lighten-1 font-weight-bold font-color-white"
        }
      ]
    }
  },
  filters: {
    formatarMoeda: function(value) {
      if (!value) return ""
      return 'R$ ' + value.toFixed(2).replace(".", ",")
    }
  }
}
</script>

<style scoped>
.order {
  border-radius: 10px;
  padding-top: 0 10px 0 10px;
  /* background: #eeeeee; */
}
td:nth-child(3) {
    color: red;
    background-color: #FFF;  
}

@media only screen and (max-width: 600px) {
  td:nth-child(3) {
    color: red;
    
    background-color: red;  
    color: #FFF;
  }
}
.green {
  background-color: green;
}
#simulator_card {
  margin: 0 auto; 
  text-align:center; 
  border: 1px solid #e3e3e3;
  border-radius: 3px; 
  font-size: 1.1em;
}
.simulator_card_info {
  padding: 10px 50px;
}
@media only screen and (max-width: 600px) {
  .simulator_card_info{
    padding: 10px; }
}

.simulator_card_info #simulator_result_value{
  color: #A4C425; 
  font-size: 2em;
  padding: 10px 0; 
  font-weight: bold
}
</style>