<template>
  <div>
    <!-- <div class="pb-2 pt-4 px-3" style="background-color: #efefef; border-radius: 10px">
      <div class="headline tertiary--text">Somente Cemig <small style="font-size: 12px">(sem Enercred)</small></div>

      <div class="order">
        <table width="100%" style="color:red; width:100%">
          <tr>
            <td style="width:70%">Você gasta em média</td>
            <td style="width:30%">R$ {{ lead.average_consumption * 1 | formatarMoeda }}</td>
          </tr>
        </table>
      </div>
    </div> -->
    <div class="pb-2 pt-0 px-3" style="background-color: #fff; border-radius: 10px">
      <!-- <div class="headline pt-5 pb-3 text-primary text--darken-3 ">Com a Enercred</div> -->

      <div class="order">
        <table style="width: 100%">
          <template>
            <tr>
              <td class="`pl-3" style="width:70%">
                Percentual de aluguel
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon color="tertiary" dark slot="end" small v-on="on">help</v-icon>
                  </template>
                  <span style="color: #FFF">
                    O percentual determina quanto de energia você receberá por mês, 
                    se a usina produzir 221.000 kwh e você está alugando {{(uc.percentage) | formatarMoeda}}%
                    receberá aproximadamente {{uc.creditos | formatarMoeda}} por mês</span>
                </v-tooltip>
              </td>
              <td  style="width:30%"
                class="`pr-3 text-xs-right  align-end`"
              >
                {{ uc.percentage | formatarMoeda }}%
              </td>
            </tr>
            <tr>
              <td class="`pl-3"  style="width:70%">
                <!-- <p>Recebe <span style='font-weight:800; color: teal'> R$ {{uc.creditos | formatarMoeda }} </span> em créditos na sua conta de luz e paga apenas*</p> -->
                Créditos de Energia
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon color="tertiary" dark slot="end" small v-on="on">help</v-icon>
                  </template>
                  <span style="color: #FFF">
                    O crédito de energia limpa é o desconto que você recebe na sua conta de energia referente a energia gerado por nossa usina solar.</span>
                </v-tooltip>
              </td>
              <td  style="width:30%"
                class="`pr-3 text-xs-right  align-end`"
              >
                R$ {{ uc.creditos | formatarMoeda }}
              </td>
            </tr>
            <tr>
              <td class="`pl-3" style="width:70%">
                <!-- <p>Recebe <span style='font-weight:800; color: teal'> R$ {{uc.creditos | formatarMoeda }} </span> em créditos na sua conta de luz e paga apenas*</p> -->
                Mensalidade
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon color="tertiary" dark slot="end" small v-on="on">help</v-icon>
                  </template>
                  <span style="color: #FFF">
                    Valor que você paga para a Enercred pela energia recebida, 
                    na mensalidade você recebe {{uc.percentage_discount}}% de desconto sobre os créditos recebidos.
                    </span>
                </v-tooltip>
              </td>
              <td  style="width:30%"
                class="`pr-3 text-xs-right  align-end`"
              >
                (R$ {{ uc.mensalidade | formatarMoeda }})
              </td>
            </tr>
            <tr>
              <td class="`pl-3"  style="width:70%">
                <!-- <p>Recebe <span style='font-weight:800; color: teal'> R$ {{uc.creditos | formatarMoeda }} </span> em créditos na sua conta de luz e paga apenas*</p> -->
                Economia Mensal
                <!-- <v-tooltip bottom v-if="props.item.tooltip">
                  <template v-slot:activator="{ on }">
                    <v-icon color="tertiary" dark slot="end" v-on="on">help</v-icon>
                  </template>
                  <span>{{props.item.tooltip}}</span>
                </v-tooltip> -->
              </td>
              <td  style="width:30%"
                class="`pr-3 text-xs-right  align-end`"
              >
                = R$ {{ (uc.economia_mensal) | formatarMoeda }}
              </td>
            </tr>
            <tr>
              <td class="`pl-3"  style="width:70%">
                <!-- <p>Recebe <span style='font-weight:800; color: teal'> R$ {{uc.creditos | formatarMoeda }} </span> em créditos na sua conta de luz e paga apenas*</p> -->
                Economia Anual
                <!-- <v-tooltip bottom v-if="props.item.tooltip">
                  <template v-slot:activator="{ on }">
                    <v-icon color="tertiary" dark slot="end" v-on="on">help</v-icon>
                  </template>
                  <span>{{props.item.tooltip}}</span>
                </v-tooltip> -->
              </td>
              <td style="width:30%; font-weight: 900; font-size: 1.1rem"
                class="`pr-3 text-xs-right  green--text align-end`"
              >
                R$ {{ ((uc.economia_mensal) * 12) | formatarMoeda }}
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["uc"],
  data() {
    return {
      headers: [
        {
          text: "Economia",
          align: "left",
          value: "name"
        }
      ],
      // items: [
      //   {
      //     name: "Recebe <span style='font-weight:800; color: teal'> R$" + ((this.lead.average_consumption * 1) * (1 - 0.09) - 50).toFixed(2).replace(".", ",") + "</span> em créditos na sua conta de luz e paga apenas*",
      //     // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao)
      //     value: ((this.lead.average_consumption) * (1 - 0.09) - 50) * 0.9,
      //     color: "white lighten-3"
      //   },
      //   {
      //     name: "Continua pagando para a Cemig",
      //     // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao) * 90%
      //     value: (this.lead.average_consumption * 1) - (this.lead.average_consumption * (1 - 0.09) - 50),
      //     color: "white lighten-3",
      //     tooltip: "As normas brasileiras de energia obrigam que você pague um valor mínimo para a concessionária."
      //   },
      //   {
      //     name: "Agora você gasta menos<p><small>Cemig + Enercred<small></p>",
      //     // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao) * 90%
      //     value: ((this.lead.average_consumption * (1 - 0.09) - 50) * 0.9) + (this.lead.average_consumption - (this.lead.average_consumption * (1 - 0.09) - 50)),
      //     color: "white lighten-3"
      //   },
      //   {
      //     name: "Economia Mensal",
      //     // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao) * 10%
      //     value: (this.lead.average_consumption * (1 - 0.09) - 50) * 0.1,
      //     color: "teal lighten-3 font-weight-bold"
      //   },
      //   {
      //     name: "Economia Anual",
      //     // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao) * percentual_desconto * 12;
      //     value: (this.lead.average_consumption * (1 - 0.09) - 50) * 0.1 * 12,
      //     color: "teal lighten-1 font-weight-bold font-color-white"
      //   }
      // ]
    }
  },
  filters: {
    formatarMoeda: function(value) {
      if (!value) return ""
      if (value === '') return ""
      if (Number(value) === 'NaN') return ""
      return Number(value).toFixed(2).replace(".", ",")
    }
  }
}
</script>

<style scoped>
.order {
  border-radius: 10px;
  padding-top: 0 10px 0 10px;
  font-size: 14px;
  /* background: #eeeeee; */
}
td:nth-child(3) {
    color: red;
    background-color: #FFF;  
}

@media only screen and (max-width: 600px) {
  td:nth-child(3) {
    color: red;
    
    background-color: red;  
    color: #FFF;
  }
}
.green {
  background-color: green;
}
</style>