<template>
  <v-app>
    <core-view />
  </v-app>
</template>
<script>
export default {
  created() {
    this.$store.dispatch("tryAutoLogin");
  }
};
</script>


<style lang="scss">
@import "@/styles/index.scss";
#app {
  font-family: "Poppins", cursive !important;
}
.display-1,
.display-2,
.display-3,
.title,
.headline {
  font-family: "Poppins", cursive !important;
}
/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>