// import Vue from 'vue'
// import Vuetify from 'vuetify'
// import theme from './theme'
// import 'vuetify/dist/vuetify.min.css'
// import '@mdi/font/css/materialdesignicons.css'
// import { mdiAccount } from '@mdi/js'

// Vue.use(Vuetify, {
//   iconfont: 'mdi' || mdiAccount,
//   theme
// })

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

Vue.use(Vuetify, {
  iconfont: 'md',
})