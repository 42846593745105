import Vue from 'vue'

import VueResource from 'vue-resource'

Vue.use(VueResource);

import amplitude from 'amplitude-js';

// Components
import './components'

// Plugins
import './plugins'

// Sync router with store
import { sync } from 'vuex-router-sync'

import './plugins/vuetify'
// import 'vuetify/dist/vuetify.min.css'
// import '@babel/polyfill'
import App from './App.vue'
import i18n from '@/i18n'

import axios from 'axios'
import router from '@/router'

// import firebase from 'firebase'
import {firebaseApp} from './firebase/firebase'

import store from '@/store'
import { createProvider } from './vue-apollo'

// Sync store with router
sync(store, router)


// axios.defaults.baseURL = process.env.VUE_APP_FB_baseURL
axios.defaults.headers.get['Accepts'] = 'application/json'
axios.defaults.baseURL = process.env.VUE_APP_USER_API_BASE
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*'

const reqInterceptor = axios.interceptors.request.use(config => {
  // console.log('Request Interceptor', config)
  return config
})
const resInterceptor = axios.interceptors.response.use(res => {
  // console.log('Response Interceptor', res)
  return res
})

axios.interceptors.request.eject(reqInterceptor)
axios.interceptors.response.eject(resInterceptor)

Vue.config.productionTip = false

let app = ''

export const eventBus = new Vue();

import VueGtm from "vue-gtm";
Vue.use(VueGtm, {
  id: "GTM-PHSX6PM", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  // queryParams: {
  //   // Add url query string when load gtm.js with GTM ID (optional)
  //   // gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
  //   // gtm_preview: "env-4",
  //   // gtm_cookies_win: "x",
  // },
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: true, // Whether or not call trackView in Vue.nextTick
});

const isProd = process.env.NODE_ENV === 'production'
if(isProd) {
  // Vue.use(VueGtag, {
  //   config: { id: "UA-100595350-1" },
  //   includes: [
  //     { id: 'UA-100595350-5' },
  //     // { 
  //     //   id: 'UA-1234567-3',
  //     //   params: {
  //     //     send_page_view: false
  //     //   }
  //     // }
  //   ],
  //   appName: 'onboard-enercred',
  //   pageTrackerScreenviewEnabled: true
  // }, router);

  amplitude.getInstance().init("2982662131ffc5fdca61e62aa7b80ec2", null, {
    // optional configuration options
    saveEvents: true,
    includeUtm: true,
    includeReferrer: true,
    trackingOptions: {
       city: true,
       ip_address: true,
    }
  });
}

// createGtm({
//   id: "GTM-PHSX6PM", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
//   queryParams: {
//     // Add url query string when load gtm.js with GTM ID (optional)
//     // gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
//     // gtm_preview: "env-4",
//     // gtm_cookies_win: "x",
//   },
//   defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
//   compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
//   // nonce: "2726c7f26c", // Will add `nonce` to the script tag
//   enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//   debug: true, // Whether or not display console logs debugs (optional)
//   loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
//   vueRouter: router, // Pass the router instance to automatically sync with router (optional)
//   // ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
//   trackOnNextTick: true, // Whether or not call trackView in Vue.nextTick
// })

firebaseApp.auth().onAuthStateChanged(() => {
  if(!app) {
    app = new Vue({
      i18n,
      router,
      store,
      amplitude,
      apolloProvider: createProvider(),
      render: h => h(App),
    }).$mount('#app')
  }
});