<template>
    <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
			<h1 class="display-2 font-weight-medium pa-3 text-md-center blue--text text--darken-3">Faça parte de uma comunidade que ajuda o planeta enquanto economiza na conta de energia</h1>
			<!-- <v-list three-line>
				<template v-for="(item, index) in items">
					<v-subheader
					v-if="item.header"
					:key="item.header"
					>
					{{ item.header }}
					</v-subheader>

					<v-divider
					v-else-if="item.divider"
					:key="index"
					:inset="item.inset"
					style="margin: 10px 10px 10px 10px"
					block
					></v-divider>

					<v-list-tile
					v-else
					:key="item.title"
					avatar
					>
					<v-list-tile-avatar>
						<v-img v-if="item.avatar" :src="item.avatar"/>
						<v-icon v-else :color="item.icon_color">{{item.icon}}</v-icon>
					</v-list-tile-avatar>

					<v-list-tile-content>
						<v-list-tile-title v-html="item.title" class="title"></v-list-tile-title>
						<v-list-tile-sub-title v-html="item.subtitle"></v-list-tile-sub-title>
					</v-list-tile-content>
					</v-list-tile>
				</template>
			</v-list> -->
        </v-layout>
    </v-container>
</template>
<script>
export default {
  data () {
      return {
        items: [
          { header: 'Benefícios' },
          {
			icon: 'favorite',
			icon_color: 'red',
			avatar: require('@/assets/beneficios/pagamento.jpg'),
            title: 'Sem taxa de adesão',
            subtitle: "Você começa a pagar apenas após receber os créditos em sua conta de energia."
          },
          { divider: true, inset: true },
          {
			icon: 'favorite',
			icon_color: 'red',
			avatar: require('@/assets/beneficios/fidelidade.jpg'),
            title: 'Sem fidelidade',
            subtitle: "Basta solicitar o cancelamento com 90 dias de antecedência."
          },
          { divider: true, inset: true },
          {
			icon: 'favorite',
			icon_color: 'red',
			avatar: require('@/assets/beneficios/obras.jpg'),
            title: 'Sem obras e manutenção',
            subtitle: "Esqueça obras ou ter de se preocupar com a manutenção"
          },
          { divider: true, inset: true },
          {
			icon: 'favorite',
			icon_color: 'red',
			avatar: require('@/assets/beneficios/creditcard.jpg'),
            title: 'Pagamento Fácil',
            subtitle: "Você pode escolher pagar por cartão de crédito ou boleto"
          },
          { divider: true, inset: true },
          {
			icon: 'favorite',
			icon_color: 'red',
			avatar: require('@/assets/beneficios/mobile.jpg'),
            title: 'Tudo pelo App',
            subtitle: "Você pode acompanhar suas faturas, sua economia, indicar para amigos..."
          }
        ]
      }
	},
}
</script>
<style scoped>
	.economia {
		color: #A4C425; 
		font-size: 1.2em;
		padding: 10px 0; 
		font-weight: bold;
	}
	.title {
		/* font-size: 1.5em; */
		font-weight: bold;
		/* padding: 0;  */
		color: #095F96;
	}
</style>