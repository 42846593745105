<template>
    <v-flex
        xs12
        md12
    >
        <v-overlay :value="loading">
            Gerando Contrato.
            Isso pode levar alguns segundos...
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div id='signature-div' v-if="!this.loading"></div>
        <v-snackbar
            :color="snack.color"
            :bottom="snack.bottom"
            :top="snack.top"
            :left="snack.left"
            :right="snack.right"
            v-model="snack.snackbar"
            dark
        >
            <v-icon
                color="white"
                class="mr-3"
            >
                mdi-bell-plus
            </v-icon>
            <div>{{snack.snackMessage}}</div>
        </v-snackbar>
    </v-flex>
</template>
<script>
import { updateLead } from '../../services/leads'
import { SET_CONTRACT } from '../../graphql/contracts'

export default {
    data: () => ({
        loading: true,
        contrato: {},
        overlay: this.loading,
        lead: {},
        snack: {
            color: null,
            top: true,
            bottom: false,
            left: false,
            right: false,
            snackMessage: '',
            snackbar: false,
        },
        proposals: [],
        proposal: {},
    }),
    beforeMount() {
        this.loading = true
    },
    mounted() {
        // console.log(JSON.parse(localStorage.getItem("lead")));
        // Verifica se o parametro email foi passado na URL
        this.loading = true;
        this.lead = JSON.parse(localStorage.getItem("lead"))
        // ('lead: ', this.lead)
        this.proposal = this.lead.proposal
        // console.log('proposal: ', this.proposal)
        this.doContract(this.lead, this.proposal)
            .then((result) => {
                // this.loading = result.loading
                this.loading = false
                // console.log('uuid: ', result.data.createContractPF.uuid)
                // console.log('key-signer: ', result.data.createContractPF.signers)
                // eslint-disable-next-line
                d4sign.configure({
                    container: 'signature-div',
                    key: result.data.createContractPF.uuid,
                    protocol: 'https',
                    host: process.env.VUE_APP_D4SIGN_HOST,
                    signer: {
                        email: this.lead.email,
                        // key_signer: result.data.createContractPF.signers[0].key_signer
                    },
                    width: '1025',
                    height: '400',
                    callback: function(event) {
                        if (event.data === "signed") {
                            alert('ASSINADO');
                            this.loading = true
                            this.lead.contractSigned = true
                            updateLead(this.lead.email, this.lead)
                                .then(()=>{
                                    this.snack('Parabéns! Contrato assinado. Você receberá um email com os próximos passos.','error','top')
                                    this.loading = false
                                })
                                .catch(error => {
                                    console.error('Erro ao atualizar os dados. ', error)
                                    this.snack('Erro ao atualizar cliente com a assinatura do contrato. Entre em contato com suporte@enercred.com.br','error','top')
                                    this.loading = false
                                })
                        }
                    }
                });
            })
            .catch(error => {
                console.error('Erro ao atualizar os dados. ', error)
                this.snack('Erro ao gerar contrato. Entre em contato com suporte@enercred.com.br','error','top')
            })
    },
    methods: {
        async doContract(lead, proposal) {
            const signers = [{
                email: lead.email,
                act: '1',
                foreign: '0',
                certificadoicpbr: '0',
                assinatura_presencial: '0'
            }]
            const location = {
                safe: process.env.VUE_APP_D4SIGN_SAFE,
                folder: process.env.VUE_APP_D4SIGN_FOLDER
            }
            const contractCover = {
                name: proposal.name,
                credit: proposal.creditos.toString() || '0',
                amount: proposal.mensalidade.toString() || '0',
                discount: proposal.economia_contrato.toString() || '0',
                percentage_discount: proposal.discount.toString() || '0',
            }
            const contract = {
                name: proposal.name,
                cpf: proposal.cpf,
                birthday: lead.birthday,
                credit: proposal.creditos.toString() || '0',
                amount: proposal.mensalidade.toString() || '0',
                percentage: (proposal.percentual_planta).toString() || '0',
                discount: proposal.economia_contrato.toString() || '0',
                nationality: lead.nationality,
                marital_status: lead.marital_status,
                property_regime: lead.property_regime,
                profession: lead.profissao || this.lead.profession || '',
                document_id: lead.ID_document,
                dispatching_agency: lead.orgao_emissor,
                address: {
                    address: proposal.address,
                    address_number: '',
                    district: proposal.district,
                    city: proposal.city,
                    state: {
                        initials: proposal.state,
                        name: '',
                    },
                    postal_code: this.proposal.postal_code
                }
            }
            const adhesionterm = {
                name: proposal.name,
                cpf: proposal.cpf,
                address: {
                    address: proposal.address,
                    address_number: '',
                    district: proposal.district,
                    city: proposal.city,
                    state: {
                        initials: proposal.state,
                        name: '',
                    },
                    postal_code: proposal.postal_code
                }
            }
            const message = {
                skip_email: '0',
                workflow: '0'
            }
            // console.log(signers)
            // console.log(location)
            // console.log(contractCover)
            // console.log(contract)
            // console.log(adhesionterm)
            // console.log(message)
            // console.log(SET_CONTRACT)
            await this.$apollo.mutate({
                mutation:SET_CONTRACT,
                variables: {
                    signers: signers,
                    location: location,
                    contractCover: contractCover,
                    contract: contract,
                    adhesionterm: adhesionterm,
                    message: message
                }
            })
        },
        snack (message, color, ...args) {
            this.snack.top = false
            this.snack.bottom = false
            this.snack.left = false
            this.snack.right = false

            for (const loc of args) {
                this[loc] = true
            }

            this.snack.color = color  
            this.snack.snackMessage = message
            this.snack.snackbar = true
        },
    }


}
</script>