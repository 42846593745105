/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: "/cadastro",
    name: "Cadastro",
    // view: "cadastro_novo/Cadastro",
    view: "assinatura/Passo1",
    meta: {
      fullpage: true,
      public: true,
      gtm: "cadastro"
    }
  },
  {
    path: "/cadastro/lp/:id",
    name: "Landing Page",
    // view: "cadastro_novo/Cadastro",
    view: "assinatura/Economico",
    meta: {
      fullpage: true,
      public: true,
    }
  },
  {
    path: "/cadastro/lp",
    name: "Landing Page",
    // view: "cadastro_novo/Cadastro",
    view: "assinatura/Economico",
    meta: {
      fullpage: true,
      public: true,
    }
  },
  {
    path: "/assinatura",
    name: "passo1",
    // view: "cadastro/Cadastro",
    view: "assinatura/Passo1",
    meta: {
      fullpage: true,
      public: true,
      gtm: "passo1"
    }
  },
  {
    path: "/assinatura/2",
    name: "passo2",
    // view: "cadastro/Cadastro",
    view: "assinatura/Passo2",
    meta: {
      fullpage: true,
      public: true,
      gtm: "passo2"
    }
  },
  {
    path: "/assinatura/3",
    name: "passo3",
    // view: "cadastro/Cadastro",
    view: "assinatura/Passo3",
    meta: {
      fullpage: true,
      public: true,
      gtm: "passo3"
    }
  },
  {
    path: "/assinatura/4",
    name: "passo4",
    view: "assinatura/Passo4",
    meta: {
      fullpage: true,
      public: true,
      gtm: "passo4"
    }
  },
  {
    path: "/assinatura/boas-vindas",
    name: "BoasVindas",
    view: "assinatura/BoasVindas",
    meta: {
      fullpage: true,
      public: true,
      gtm: "boas-vindas"
    }
  },
  {
    path: "/assinatura/cartao",
    name: "assinaturaCartao",
    view: "assinatura/CadastroCartao",
    meta: {
      fullpage: true,
      public: true,
      gtm: "cartao"
    }
  },
  {
    path: "/cadastrar",
    name: "CadastroB",
    view: "cadastro/Cadastro",
    // view: "cadastro_proposta/Cadastro",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/economizar",
    name: "Economizar",
    view: "cadastro_novo/Cadastro",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastro-assinatura",
    name: "CadastroAssinatura",
    view: "cadastro/CadastroAssinatura",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastro/unidadeconsumidora",
    name: "UnidadeConsumidora",
    view: "cadastro/CadastroUnidadeConsumidora",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastro/assinatura",
    name: "AssinaturaNovo",
    view: "cadastro_novo/CadastroAssinatura",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastrar/solicitar-proposta",
    name: "Proposta",
    view: "cadastro_proposta/CadastroSolicitarProposta",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastrar/assinatura",
    name: "Assinatura",
    view: "cadastro/CadastroAssinatura",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastrar/pre-contrato",
    name: "Pré Contrato",
    view: "cadastro_proposta/CadastroPreContrato",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastro-pessoal",
    name: "CadastroPessoal",
    view: "cadastro/CadastroPessoal",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastro/pessoal",
    name: "PessoalNovo",
    view: "cadastro_novo/CadastroPessoal",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastrar/pessoal",
    name: "Pessoal",
    view: "cadastro/CadastroPessoal",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastrar/solicitacao-proposta",
    name: "SolicitacaoProposta",
    view: "cadastro_proposta/CadastroBoasVindas",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastrar/proposta",
    name: "CadastroProposta",
    view: "cadastro_proposta/CadastroProposta",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastro-boas-vindas",
    name: "CadastroBoasVindas",
    view: "cadastro_novo/CadastroBoasVindas",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/bem-vindo",
    name: "BemVindo",
    view: "cadastro_novo/CadastroBoasVindasCartao",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastro-cartao",
    name: "CadastroCartao",
    view: "cadastro/CadastroCartao",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastro/cartao",
    name: "CartaoNovo",
    view: "cadastro_novo/CadastroCartao",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastrar/cartao",
    name: "Cartao",
    view: "cadastro/CadastroCartao",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastro-concluido",
    name: "CadastroConcluido",
    view: "cadastro/CadastroConcluido",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/lista-espera",
    name: "Lista de Espera",
    view: "cadastro/ListaEspera",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/economia",
    name: "Economia",
    view: "lp/Economia",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/indicacao",
    name: "Indicação",
    view: "lp/Indicacao",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/boleto/confirmacao",
    name: "ConfirmacaoBoleto",
    view: "boleto/confirmacao",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/escolher-metodo-pagamento",
    name: "Confirmação de Boleto",
    view: "forma_pagamento/SelecionarFormaPagamento",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/cadastrar/concluido",
    name: "CadastroPropostaConcluido",
    view: "cadastro_proposta/CadastroConclusao",
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: "/simular",
    name: "Simulador",
    view: "onboard3/Simulador",
    meta: {
      fullpage: true,
      public: true
    }
  },
]
