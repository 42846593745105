<template>
    <v-card>
        <v-form v-model="valid">
            <h6>Faça uma silumação</h6>
            <v-container>
            <v-row>
                <v-col
                cols="12"
                md="4"
                >
                    <v-text-field
                        v-model="average_consumption"
                        :rules="nameRules"
                        :counter="10"
                        label="First name"
                        required
                    ></v-text-field>
                </v-col>

                <v-col
                cols="12"
                md="4"
                >
                <v-text-field
                    v-model="lastname"
                    :rules="nameRules"
                    :counter="10"
                    label="Last name"
                    required
                ></v-text-field>
                </v-col>

                <v-col
                cols="12"
                md="4"
                >
                <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                ></v-text-field>
                </v-col>
            </v-row>
            </v-container>
        </v-form>
    </v-card>
</template>
<script>
export default {
    data() {
        return {
            valid: false,
            average_consumption: 0
        }
    }
}
</script>