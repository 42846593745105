import Http from './http'; // não precisa de .js

import axios from 'axios'

const token = !localStorage.getItem('token')?false:localStorage.getItem('token')
if(token)
  axios.defaults.headers.get['Authorization'] = 'Bearer ' + token

export const getLead = (email) => {
    return Http.get('/getLead/' +  email );
};

export const getLeads = () => {
  return axios.get('/getLeads', {headers: {'Authorization': 'Bearer ' + token}} );
};

export const addLead = (lead) => Http.post('/addLead', lead);

export const updateLead = (email, lead) => Http.put('/updateLead/' + email, lead);