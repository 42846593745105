<template>
    <!-- Header Mobile --> 
    <v-layout row wrap>
        <v-flex xs6 sm6 md12 class="text-md-left">
        <a
            class="d-inline-block"
            href="https://enercred.com.br"
            target="_blank"
            alt="Enercred - Energia Limpa e Barata"
        >
            <img
            src="@/assets/logo.svg"
            width="150px"
            style="padding: 5px"
            alt="Enercred - Energia Limpa e Barata"
            class="src"
            />
        </a>
        </v-flex>
        <v-flex v-if="hasDuvidas" xs6 sm6 md12 class="text-sm-right align-end" style="text-align: right; padding-right: 12px">
            <!-- <v-btn
                color="primary"
                href="#faq"
                class="hidden-md-and-up"
                outline
                small
            >Dúvidas?</v-btn> -->
        </v-flex>
    </v-layout>
    <!-- FIM Header Mobile -->
</template>
<script>
export default {
    props: ['hasDuvidas']
}
</script>

<style scoped>
@charset "ISO-8859-1";

.header {
    padding-top: 40px;
    padding-bottom: 0px;
    font-size: 1.5em;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
    display: table-cell;
    color: rgb(51, 51, 51);
}
.header span {
    font-size: 1.5em;
    color: #666;
    font-weight: bold;
}
.header h1 {
    font-size: 1.8em;
    color: #666;
    font-weight: bold;
}
.header br {
    font-size: 3em;
    color: #666;
    font-weight: bold;
}
</style>