import axios from 'axios';

const token = localStorage.getItem('token')
// console.log('token: ', token)

axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.get['Content-Type'] = 'application/json'
if(token) {
  axios.defaults.headers.get['Authorization'] = 'Bearer ' + token
}

var client;

/*
var config;
// console.log('token: ', token)
if(token) {
  config = {
    baseURL: process.env.VUE_APP_USER_API_BASE,
    timeout: 10000,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  }
} else {
  config = {
    baseURL: process.env.VUE_APP_USER_API_BASE,
    timeout: 10000,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }
  }
}
*/
// console.log('config: ', config)
client = axios.create()
export default client;
