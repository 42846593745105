import { render, staticRenderFns } from "./HelloWorld.vue?vue&type=template&id=023580a6&"
import script from "./HelloWorld.vue?vue&type=script&lang=js&"
export * from "./HelloWorld.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/Users/rodri/OneDrive/Documentos/GitHub/enercred-onboard/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
installComponents(component, {VContainer,VImg})
