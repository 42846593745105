<template>
  <v-content>
    <div v-if="$route.name !== 'Maps' && $route.meta.fullpage !== true" id="core-view">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </div>
    <div v-if="$route.name === 'Maps' || $route.meta.fullpage === true">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </div>
    <!-- <core-footer v-if="$route.name !== 'Maps' && $route.meta.fullpage !== true" /> -->
  </v-content>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: 'Enercred - Energia Limpa e Barata'
    }
  }
}
</script>

<style>
#core-view {
  padding-bottom: 100px;
}
</style>
