import gql from 'graphql-tag'

export const SET_CONTRACT = gql`
    mutation createContractPF(
        $signers: [InputD4Signer]!,
        $location: InputD4Location,
        $contractCover: InputD4ContractCoverPF,
        $contract: InputD4ContractPF,
        $adhesionterm: InputD4AdhesionTerm,
        $message: InputD4Message
    ){
        createContractPF(
            signers: $signers,
            location: $location,
            contractCover: $contractCover,
            contract: $contract,
            adhesionterm: $adhesionterm,
            message: $message
        ) {
            uuid,
            message,
            signers {
                key_signer
            }
        }
    }`