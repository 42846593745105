<template>
  <v-expansion-panel style="box-shadow: none">
    <v-expansion-panel-content
      v-for="(faqItem, index) in faqItems"
      :key="index"
      style="border-radius: 5px;"
      class="mb-2"
    >
      <template v-slot:actions>
        <v-icon color="rgb(0, 168, 187)">$vuetify.icons.expand</v-icon>
      </template>
      <template v-slot:header>
        <div
          class="font-weight-bold"
          style="font-size: 0.7em; color: rgb(0, 168, 187)"
        ><p v-html="faqItem.question"/></div>
      </template>
      <v-card>
        <v-card-text
          class="font-weight-bold"
          style="font-size: 0.7em; background-color: rgb(0, 168, 187); color: #fff"
        ><p v-html="faqItem.answer"/></v-card-text>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "Faq",
  data: function() {
    return {
      faqItems: [
        {
          question: "1. O que é a assinatura de energia limpa da Enercred?",
          answer:
            "É o aluguel de um percentual de nossas usinas solares. Muitas pessoas não possuem condições de instalar seu próprio sistema solar, seja por falta de espaço físico, morar de aluguel, morar em apartamento ou razões financeiras. Com a Enercred você aluga um percentual de nossas usinas e a energia chega em forma de créditos na conta da Cemig. Sem necessidade de obras na sua residência."
        },
        {
          question: "2. Por que preciso pagar para economizar?",
          answer:
            "Para economizar você aluga um percentual de nossas usinas solares e recebe créditos, que reduzem o valor da sua conta da CEMIG. Para a Enercred você pagará, por cartão de crédito, pelos créditos recebidos com desconto de 10%. <br>Exemplo: se você recebe R$ 200,00 em créditos na conta da CEMIG você paga para a Enercred R$ 180,00,  economizando R$ 20,00 todo mês. <br>Ou seja, antes você pagava só a sua conta da CEMIG, agora você paga duas contas que somadas são mais baratas do que a conta antiga!"
        },
        {
          question: "3. Quanto vou ter que pagar?",
          answer:
            "A lógica da cobrança é a seguinte: Vamos supor que você tem uma conta com o consumo mensal de R$300 e assinou a Enercred e que no mês de fevereiro nós conseguimos reduzir a sua conta em R$200. Isso significa que você vai pagar pra CEMIG R$100 e para a Enercred o restante com um desconto de 10%, ou seja, R$180. A sua conta da CEMIG que antes era de R$300 agora se transforma em duas contas (CEMIG + Enercred) que somadas ficam no valor de R$280. Portanto, assinando a Enercred você economiza R$20,00. Mantendo este padrão, você economizaria R$240, o que é próximo de uma conta de luz por ano de economia."
        },
        {
          question: "4. Por quanto tempo? Há tempo de fidelidade?",
          answer:
            "Não temos um tempo definido de contrato. Porém, como diz o termo de serviço, só é necessário que você nos avise com 90 dias de antecedência caso deseje cancelar. Se por acaso a necessidade de cancelamento for imediata você terá que nos pagar uma multa no valor de 3 mensalidades da assinatura."
        },
        {
          question: "5. Quais são as formas de pagamento e quando irei pagar?",
          answer:
            "O pagamento da assinatura pode ser feito por cartão de crédito ou boleto. <br><br>Você somente começa a pagar após receber os créditos em sua conta de energia, ou seja, maior segurança de economia para você."
        },
        {
          question: "6. Eu continuo recebendo a conta da CEMIG?",
          answer:
            "Sim. Ao contratar a Enercred você continua recebendo a conta de luz da CEMIG, porém com um valor bem mais baixo devido ao créditos de energia limpa que você recebe da Enercred. Além dela, você receberá a fatura da Enercred em seu cartão. Vale lembrar que a soma da fatura da Enercred mais a conta da CEMIG é mais barata do que a conta que você pagaria para a CEMIG sem assinar a Enercred!"
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>