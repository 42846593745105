/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import firebase from "firebase";

// Routes
import paths from "./paths";

import store from "../store/index";

function route(path, meta, view, name) {
  return {
    name: name || view,
    path,
    meta,
    component: resovle => import(`@/views/${view}.vue`).then(resovle)
  };
}

Vue.use(Router);

// Create a new router
let url = window.location.origin
console.log('url: ', window.location)
let pathname = window.location.pathname
let search = window.location.search
if(pathname === '/cadastro/cartao') window.location.href = 'https://cadastro.enercred.com.br/metodos-pagamento' + search
else window.location.href = 'https://cadastro.enercred.com.br' + search
// let pathname = window.location.pathname
let subdomain = url.slice(url.lastIndexOf("/") + 1).split(".")[0]
let new_route = '/cadastro'
// console.log('subdomain.substring(0,5): ', subdomain.substring(0,5))
// console.log('subdomain: ', subdomain)
// console.log('pathname: ', pathname)
// if(subdomain.substring(0,5) === 'radio' || subdomain === 'saibamais' || (subdomain === 'olivia' && pathname === '/')) {
if(subdomain.substring(0,5) === 'radio' || subdomain === 'saibamais') {
  new_route = '/economia'
}
console.log(subdomain)

// console.log('new_route: ', new_route)
const router = new Router({
  mode: "history",
  routes: paths
    .map(path => route(path.path, path.meta, path.view, path.name))
    // .concat([{ path: "*", redirect: "/cadastro" }]),
    .concat([{ path: "*", redirect: new_route}]),
    
  scrollBehavior(to, from, savedPosition) {
    // console.log('scrollBehavior: ', savedPosition)
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

// router.beforeEach((to, from, next) => {
//   console.log('to: ', to)
//   console.log('next: ', next)
//   if(to.path === "/cadastro/cartao") next({path: 'https://cadastro.enercred.com.br/metodos-pagamento', query: to.query})
//   if (store.state.idToken) {
//     // console.log('idToken Router: ', store.state.idToken);
//   }
//   const currentUser = firebase.auth().currentUser;
//   const logedUser = JSON.parse(localStorage.getItem("user"));
//   // console.log('to: ', to)
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//   // console.log('requiresAuth: ', requiresAuth)
//   const publicRoute = to.matched.some(record => record.meta.public);
//   // console.log('publicRoute: ', publicRoute)
//   const requiresGroup = to.matched.some(record => record.meta.requiresGroup);
//   // console.log('requiresGroup: ', requiresGroup)
//   if (publicRoute) next();
//   else if (requiresAuth && currentUser) {
//     if (requiresGroup) {
//       // console.log('logedUser: ', logedUser)
//       if (logedUser.group === to.meta.requiresGroup) {
//         next();
//       } else {
//         next("/login");
//       }
//     } else {
//       next();
//     }
//   } else if (requiresAuth && !currentUser) next("/login");
//   else next("/login");
// });

Vue.use(Meta);

export default router;
