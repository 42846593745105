<template>
    <v-container grid-list-md text-xs-center class="plano" :style="`border: 1px solid ${color}`">
        <v-layout row wrap>
			<v-flex xs12 class="titulo">
				<div>Plano Sustentabilidade {{discount*100}}</div>
			</v-flex>
			<v-flex xs12>
				<span>Pagando por {{plan}}</span>
			</v-flex>
			<v-flex md6 xs12 class="hidden-md-and-up">
				<img src="../../assets/img/save-money.jpg" width="150px"/>
			</v-flex>
			<v-flex sm12 xs12>
				<div class="economia">Economize até</div>
				<div class="economia_valor">{{(lead.average_consumption * (1 - 0.09) - 50) * discount * 12 | formatarMoeda}}</div>
				<div>por ano</div>
				<div id="simulator_result_mensal" style="color:#666; font-size: 0.8rem">{{(lead.average_consumption * (1 - 0.09) - 50) * discount | formatarMoeda}} por mês</div>
			</v-flex>
			<v-flex xs12>
				<div class="creditos" :style="`background: ${color}`">Receba em média <span class='recebe'>{{((lead.average_consumption * 1) * (1 - 0.09) - 50) | formatarMoeda}} </span> em créditos na sua conta de luz e paga apenas {{((lead.average_consumption) * (1 - 0.09) - 50) * (1 - discount) | formatarMoeda}}</div>
			</v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
	props: ["lead", "discount", "color", "plan"],
	filters: {
		formatarMoeda: function(value) {
		if (!value) return ""
		return 'R$ ' + value.toFixed(2).replace(".", ",")
		}
	}
}
</script>
<style scoped>
	.economia {
		color: #A4C425; 
		font-size: 1.2em;
		padding: 1px 0 0 0; 
		font-weight: bold;
	}
	.economia_valor {
		font-size: 1.5em;
		font-weight: bold;
		padding: 0; 
		color: #0d67d0;
	}
	.creditos {
		/* background: #A4C425;  */
		color: #fff;
		padding: 10px;
		border-radius: 0px 0px 10px 10px;
	}
	.plano {
		/* border: 1px solid #A4C425; */
		border-radius: 10px;
		/* margin: 5px 10px; */
	}
	.titulo {
		font-size: 1.1em;
		margin-top: 10px; 
		font-weight: bold;
	}
	.recebe {
		font-weight:800;
		color: #FFF;
	}
</style>