<template>
  <div>
    <div class="hidden-sm-and-up">
      <p class="font-weight-thin title grey--text text--darken-3">Muito bem, com base no gasto de energia que nos informou, você pode alugar <kbd>{{percentual_aluguel}}</kbd> da nossa usina solar e economizar até <kbd>R${{formatarMoeda(economia_anual)}} por ano</kbd>.</p>
      <p class="font-weight-thin grey--text text--darken-2">É praticamente <strong>reduzir</strong> uma conta de luz por ano sem nenhum <strong>investimento inicial</strong>, sem <strong>nenhuma obra</strong>, um processo <strong>100% digital</strong>.</p>
      <p class="green--text text--darken-2">Preencha os dados do <kbd>titular da conta</kbd> para que possamos buscar seus dados de consumo na CEMIG e gerar uma proposta completa.</p>
    </div>
    <div class="hidden-sm-and-down">
      <p class="font-weight-thin grey--text text--darken-2 display-1 pb-5">Muito bem, com base nos seu gasto de energia você pode economizar até <kbd>R${{formatarMoeda((this.lead.average_consumption * (1 - 0.09) - 50) * 0.1 * 12)}} por ano</kbd>.</p>
      <p class="font-weight-thin title grey--text pb-4 text--darken-2">É praticamente <strong>reduzir</strong> uma conta de luz por ano sem <strong>nenhum investimento</strong> inicial, <strong>nenhuma obra</strong>, um processo <strong>100% digital</strong>.</p>
      <p class="green--text title text--darken-2">Preencha os dados do <kbd>titular da conta</kbd> para que possamos buscar seus dados de consumo na CEMIG e gerar uma proposta completa.</p>
    </div>
    <!-- <div class="pb-2 pt-4 px-3" style="background-color: #efefef; border-radius: 10px">
      <div class="headline tertiary--text">Somente Cemig <small style="font-size: 12px">(sem Enercred)</small></div>

      <div class="order">
        <table width="100%" style="color:red; width:100%">
          <tr>
            <td style="width:70%">Você gasta em média</td>
            <td style="width:30%">R$ {{ lead.average_consumption * 1 | formatarMoeda }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="pb-2 pt-1 px-3" style="background-color: #fff; border-radius: 10px">
      <div class="headline pt-5 pb-3 text-primary text--darken-3 ">Com a Enercred</div>

      <div class="order">
        <v-data-table :items="items" hide-actions hide-headers>
          <template v-slot:items="props">
            <td :class="`pl-3 ${ props.item.color }`"  style="width:70%">
              <p v-html="props.item.name"></p>
              <v-tooltip bottom v-if="props.item.tooltip">
                <template v-slot:activator="{ on }">
                  <v-icon color="tertiary" dark slot="end" v-on="on">help</v-icon>
                </template>
                <span>{{props.item.tooltip}}</span>
              </v-tooltip>
            </td>
            <td  style="width:30%"
              :class="`pr-3 text-xs-right ${ props.item.color }`"
            >
              R$ {{ props.item.value | formatarMoeda }}
            </td>
          </template>
        </v-data-table>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  props: ["lead"],
  data() {
    return {
      headers: [
        {
          text: "Economia",
          align: "left",
          value: "name"
        }
      ],
      items: [
        {
          name: "Recebe <span style='font-weight:800; color: teal'> R$" + ((this.lead.average_consumption * 1) * (1 - 0.09) - 50).toFixed(2).replace(".", ",") + "</span> em créditos na sua conta de luz e paga apenas*",
          // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao)
          value: ((this.lead.average_consumption) * (1 - 0.09) - 50) * 0.9,
          color: "white lighten-3"
        },
        {
          name: "Continua pagando para a Cemig",
          // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao) * 90%
          value: (this.lead.average_consumption * 1) - (this.lead.average_consumption * (1 - 0.09) - 50),
          color: "white lighten-3",
          tooltip: "As normas brasileiras de energia obrigam que você pague um valor mínimo para a concessionária."
        },
        {
          name: "Agora você gasta menos<p><small>Cemig + Enercred<small></p>",
          // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao) * 90%
          value: ((this.lead.average_consumption * (1 - 0.09) - 50) * 0.9) + (this.lead.average_consumption - (this.lead.average_consumption * (1 - 0.09) - 50)),
          color: "white lighten-3"
        },
        {
          name: "Economia Mensal",
          // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao) * 10%
          value: (this.lead.average_consumption * (1 - 0.09) - 50) * 0.1,
          color: "teal lighten-3 font-weight-bold"
        },
        {
          name: "Economia Anual",
          // value: ((gasto * (1 - percentual_desvio_padrao)) - classe_padrao) * percentual_desconto * 12;
          value: (this.lead.average_consumption * (1 - 0.09) - 50) * 0.1 * 12,
          color: "teal lighten-1 font-weight-bold font-color-white"
        }
      ]
    }
  },
  filters: {
    formatarMoeda: function(value) {
      if (!value) return ""
      return value.toFixed(2).replace(".", ",")
    }
  },
  computed: {
    percentual_aluguel() {
      let creditos = 0
      const power_monthly = process.env.VUE_APP_POWER_PLANT_POWER_MONTHLY || 160000
      if(Number(this.lead.average_consumption) > 0)
        creditos = (Number(this.lead.average_consumption) * (1 - 0.09) - 50).toFixed(2)
      // console.log('creditos: ', creditos)
      // console.log('power_monthly: ', power_monthly)
      const percent = ((creditos / power_monthly)*100).toFixed(2)
      // console.log('percent: ', percent)
      return percent.replace('.', ',') + '%'
    },
    economia_anual() {
      return (this.lead.average_consumption * (1 - 0.09) - 50) * 0.1 * 12
    }
  },
  methods: {
    formatarMoeda: function(value) {
      if (!value) return ""
      return value.toFixed(2).replace(".", ",")
    }
  }
}
</script>

<style scoped>
.order {
  border-radius: 10px;
  padding-top: 0 10px 0 10px;
  /* background: #eeeeee; */
}
td:nth-child(3) {
    color: red;
    background-color: #FFF;  
}

@media only screen and (max-width: 600px) {
  td:nth-child(3) {
    color: red;
    
    background-color: red;  
    color: #FFF;
  }
}
.green {
  background-color: green;
}

strong {
  font-weight: 900;
  font-size: 1em;
  color: darkgreen;
}

kbd {
  font-size: 1em;
  background-color: green;
}
</style>